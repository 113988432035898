import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import PostLink from "../components/post-link"
import SEO from "../components/seo"
import { resetMenu } from "../components/menu"
import { setGradient } from "../components/gradient"

const Posts = edges => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map(edge => <PostLink key={edge.node.id} post={edge.node} />)

  return <div>{Posts}</div>
}

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges }
  }
}) => {
  resetMenu();
  setGradient({
    fromColor: 'rgb(245, 253, 252)',
    toColor: 'rgb(226 180 183)'
  })

  return (
    <Layout>
      <SEO title="Resource" />    
      <div className="max-w-3xl pt-6 md:pt-12 mx-auto relative z-10">
        <div className="max-w-lg mx-4">
          <h1 className="mb-8 font-semibold text-black">
            Resources
          </h1>
            <div className="max-w-md">
              <p className="text-lg">Developer Resources, Documentation and FAQ.</p>
            </div>
          </div>
        </div>

      <div className="max-w-3xl relative z-10 pt-8 mx-auto align-top">
        <div className="w-1/2 inline-block align-top px-4">
          <h2 className="mb-4 font-semibold text-black">
            Documentation
          </h2>
          <div className="text-base text-gray-800">
            <span className="block audyem-nudge cursor-pointer">Audyem API</span>
            <span className="block audyem-nudge cursor-pointer">Self-Service Portal (SSP)</span>
            <span className="block audyem-nudge cursor-pointer">Webhooks</span>
          </div>
        </div>
        <div className="w-1/2 mt-4 md:mt-0 md:inline-block px-4">
          <h2 className="mb-4 font-semibold text-black">
              Guides
            </h2>
            <div className="text-base text-gray-800">
              <span className="block audyem-nudge cursor-pointer">Newsletter</span>
              <span className="block audyem-nudge cursor-pointer">Signups</span>
              <span className="block audyem-nudge cursor-pointer">Lead Magnets</span>
              <span className="block audyem-nudge cursor-pointer">Referrals</span>
              <span className="block audyem-nudge cursor-pointer">Polls</span>
            </div>
        </div>
      </div>

      <div className="max-w-100 w-screen absolute audyem-bg-wave -mt-32 pt-32 pb-24 md:pb-32"></div>

      <div className="w-full bg-white mt-20 pb-16">
        <div className="max-w-3xl mx-auto relative pt-4 z-10 pb-8 mb-0">
          <div className="mx-4">
            <h2 className="mb-4 font-semibold text-black">
              Facts
            </h2>
            <table className="text-left rounded-lg mt-2" style={{boxShadow: 'inset 5px 6px 5px rgb(1 1 1 / 2%)', fontSize: '.85rem'}}>
              <tbody className="align-top">
                <tr className="border-b-6 border-white">
                  <td className="font-bold text-gray-800 pt-3 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">✅</span>&nbsp; No upfront costs</td>
                  <td className="pr-4 py-2 text-gray-800 pt-3">We will not charge you until you're convinced.</td>
                </tr>
                <tr className="border-b-6 border-white">
                  <td className="font-bold text-gray-800 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">✅</span>&nbsp; GDPR compliancy</td>
                  <td className="pr-4 py-2 text-gray-800">Our customers are data-sensitive and so are we. Audyem does not make use of cookies at all. All personally identifiable data (PII) can be fully anonymized. Audyem is compliant with EU privacy law.</td>
                </tr>
                <tr className="border-white">
                  <td className="font-bold text-gray-800 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">✅</span>&nbsp; System integration</td>
                  <td className="pr-4 py-2 text-gray-800">Audyem can work with any CRM imaginable through either authenticated webhooks, custom API integration, or even enterprise event interfaces like Kafka Connect. </td>
                </tr>
                <tr className="border-white">
                  <td className="font-bold text-gray-800 pb-3 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">✅</span>&nbsp; Paperwork</td>
                  <td className="pr-4 py-2 text-gray-800 pb-3">Among our customers are some true publishing heavy-weights. We have prepared all the legal paperwork in accordance with them. From SaaS to data and privacy contracts. It's all there and approved by Audyem customers just like you.</td>
                </tr>
                <tr className="border-white">
                  <td className="font-bold text-gray-800 pb-3 px-4 py-2 w-px whitespace-no-wrap"><span role="img" aria-label="foo">✅</span>&nbsp; Pricing</td>
                  <td className="pr-4 py-2 text-gray-800 pb-3">Our pricing depends on your scale, usage and integration work. We'll give you an individual quote ASAP.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default IndexPage
export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`