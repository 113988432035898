export function resetMenu() {
  if (typeof document === `undefined`) {
    return
  }

  const menu = document.querySelector(`.audyem-mobile-menu`);
  const html = document.querySelector(`html`)

  !!menu && menu.classList.remove('audyem-mobile-menu--open');
  !!html && document.querySelector(`html`).classList.remove(`overflow-hidden`);
}